import React, { useState } from 'react';
import '../BreakthruMain.css';
import aiAssistantImage from '../images/ranprod.jpg';

const Shop = () => {
  const [expandedCard, setExpandedCard] = useState(null);

  const toggleCard = (cardId) => {
    setExpandedCard(expandedCard === cardId ? null : cardId);
  };

  const products = [
    {
      id: 'product-1',
      name: 'AI Assistant Service',
      price: 99.99,
      description: 'A comprehensive AI assistant service for your business needs.',
      image: aiAssistantImage,
      url: '/',
    },
    {
      id: 'product-2',
      name: 'Automation Toolkit',
      price: 149.99,
      description: 'A toolkit for automating your business processes.',
      image: aiAssistantImage,
      url: '/',
    },
    {
      id: 'product-3',
      name: 'Data Analytics Suite',
      price: 199.99,
      description: 'Advanced data analytics tools for better insights.',
      image: aiAssistantImage,
      url: '/',
    },
    {
      id: 'product-4',
      name: 'Predictive Maintenance Package',
      price: 249.99,
      description: 'Tools and services for predictive maintenance.',
      image: aiAssistantImage,
      url: '/',
    },
  ];

  return (
    <div className="Shop">
      <div className="hero-section">
        <div className="hero-content">
          <h1>Heading Here</h1>
          <p>Description of heading goes here.</p>
          <a href="#services" className="cta-button">Learn More</a>
        </div>
      </div>

      {/* Expandable Cards Section */}
      <div className="info-container">
        <div 
          className={`info-card ${expandedCard === 'custom-ai' ? 'expanded' : ''}`}
          onClick={() => toggleCard('custom-ai')}
        >
          <div className="card-header">
            <i className="fas fa-cogs icon"></i>
            <div>
              <h3>Service Heading Here</h3>
              <p className="cardtext">Description goes here.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'custom-ai' && <div className="card-expanded-content"><div>Article Content Here.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>

        <div 
          className={`info-card ${expandedCard === 'process-automation' ? 'expanded' : ''}`}
          onClick={() => toggleCard('process-automation')}
        >
          <div className="card-header">
            <i className="fas fa-sync-alt icon"></i>
            <div>
              <h3>Heading Here</h3>
              <p className="cardtext">Description goes here.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'process-automation' && <div className="card-expanded-content"><div>Article Content Here.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>

        <div 
          className={`info-card ${expandedCard === 'ai-integration' ? 'expanded' : ''}`}
          onClick={() => toggleCard('ai-integration')}
        >
          <div className="card-header">
            <i className="fas fa-network-wired icon"></i>
            <div>
              <h3>Heading Here</h3>
              <p className="cardtext">Description goes here.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'ai-integration' && <div className="card-expanded-content"><div>Article Content Here.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>

        <div 
          className={`info-card ${expandedCard === 'crm-automation' ? 'expanded' : ''}`}
          onClick={() => toggleCard('crm-automation')}
        >
          <div className="card-header">
            <i className="fas fa-user-friends icon"></i>
            <div>
              <h3>Heading Here</h3>
              <p className="cardtext">Description goes here.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'crm-automation' && <div className="card-expanded-content"><div>Article Content Here.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>

        <div 
          className={`info-card ${expandedCard === 'data-analysis' ? 'expanded' : ''}`}
          onClick={() => toggleCard('data-analysis')}
        >
          <div className="card-header">
            <i className="fas fa-chart-bar icon"></i>
            <div>
              <h3>Heading Here</h3>
              <p className="cardtext">Description goes here.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'data-analysis' && <div className="card-expanded-content"><div>Article Content Here.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>

        <div 
          className={`info-card ${expandedCard === 'predictive-maintenance' ? 'expanded' : ''}`}
          onClick={() => toggleCard('predictive-maintenance')}
        >
          <div className="card-header">
            <i className="fas fa-tools icon"></i>
            <div>
              <h3>Heading Here</h3>
              <p className="cardtext">Description goes here.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'predictive-maintenance' && <div className="card-expanded-content"><div>Article Content Here.</div><a href="/questionnaire" className="cta-button">Discover More</a></div>}
        </div>
      </div>

      {/* Additional AI Automation Information */}
      <div className="about-us-section about-card">
        <div className="about-content">
          <h2>Heading Here</h2>
          <p className="cardtext">Description goes here.</p>
        </div>
      </div> 

      {/* Product Section */}
      <div className="product-section">
        <h2>Products</h2>
        <div className="product-container info-container">
          {products.map(product => (
            <div 
              key={product.id} 
              className={`info-card ${expandedCard === product.id ? 'expanded' : ''}`}
              onClick={() => toggleCard(product.id)}
            >
              <img src={product.image} alt={product.name} />
              <div className="card-header">{product.name}</div>
              <p className="cardtext">{product.description}</p>
              <p>${product.price.toFixed(2)}</p>
              {expandedCard === product.id && <div className="card-expanded-content"><div>Article Content Here.</div></div>}
              <button
                className="snipcart-add-item cta-button"
                data-item-id={product.id}
                data-item-name={product.name}
                data-item-price={product.price}
                data-item-url={product.url}
                data-item-description={product.description}
                data-item-image={product.image}
              >
                Add to Cart - ${product.price.toFixed(2)}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Shop;
