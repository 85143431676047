import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Layout.css";
import logo from './images/hearticon.png';
import comp from './images/breakthru-text.png';

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setIsSpinning(!isSpinning); // Toggle the spinning state
  };

  return (
    <div>
      <nav className="navbar">
        <Link to="/" className=""><img src={logo} alt="Breakthru Fit Logo" className="navbar-logo" /></Link>        
        <img src={comp} alt="Breakthru Fit Logo Text" className="navbar-comp" />
        <div className={`hamburger ${isSpinning ? "spin" : ""}`} onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
        <div className={`menu ${isOpen ? "open" : ""}`}>
          <Link to="/" onClick={toggleMenu}><i className="fas fa-home"></i> Home</Link>
          <Link to="/shop" onClick={toggleMenu}><i className="fas fa-store"></i> Shop</Link>
          <Link to="/blog" onClick={toggleMenu}><i className="fas fa-blog"></i> Blog</Link>
          <Link to="/videos" onClick={toggleMenu}><i className="fas fa-book"></i> Resources</Link>
          <Link to="/contact" onClick={toggleMenu}><i className="fas fa-envelope"></i> Contact</Link>
        </div>
        <div className={`menu-overlay ${isOpen ? 'open' : ''}`} onClick={toggleMenu} />
      </nav>
      <main>{children}</main>
      <footer>
        <div className="footer-nav">
          <Link to="/" className="footer-icon"><i className="fas fa-home"></i><span>Home</span></Link>
          <Link to="/shop" className="footer-icon"><i className="fas fa-store"></i><span>Shop</span></Link>
          <Link to="/blog" className="footer-icon"><i className="fas fa-blog"></i><span>Blog</span></Link>
          <Link to="/resources" className="footer-icon"><i className="fas fa-book"></i><span>Resources</span></Link>
          <Link to="/contact" className="footer-icon"><i className="fas fa-envelope"></i><span>Contact</span></Link>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
