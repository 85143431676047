import React, { useState } from 'react';
import '../Contact.css'; // Ensure to import your CSS file

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Email sent successfully!');
        setFormData({ name: '', email: '', phone: '', message: '' }); // Clear form
      } else {
        alert('Failed to send email. Please try again.');
      }
    } catch (error) {
      console.error('Failed to send email', error);
      alert('Failed to send email. Please try again.');
    }
  };

  return (
    <div className="contact-page">
      <h1>Contact Us</h1>
      {/* Contact Information Section */}
      <div className="contact-card contact-info">
        <div className="card-header">
          <i className="fas fa-robot icon"></i>
          <div>
            <h3>Get in Touch</h3>
            <p>If you have any questions, please feel free to drop us a line.</p>
            <p class="cardtext"><strong>Email:</strong> info@breakthru.uk</p>
            <p class="cardtext"><strong>Phone:</strong> +1 234 567 890</p>
          </div>
        </div>
      </div>
      {/* Contact Form Section */}
      <div className="contact-form">
        <h2>Contact Form</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input 
              type="text" 
              id="name" 
              name="name" 
              required 
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input 
              type="email" 
              id="email" 
              name="email" 
              required 
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone:</label>
            <input 
              type="tel" 
              id="phone" 
              name="phone" 
              required 
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea 
              id="message" 
              name="message" 
              required 
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
          <button type="submit">Send Message</button>
        </form>
      </div>

      {/* Optional: Map or Other Relevant Information */}
      {/* ... */}
    </div>
  );
};

export default Contact;
