import React, { useState } from 'react';
import '../BreakthruMain.css';

const BreakThruFitness = () => {
  const [expandedCard, setExpandedCard] = useState(null);

  const toggleCard = (cardId) => {
    setExpandedCard(expandedCard === cardId ? null : cardId);
  };

  return (
    <div className="BreakthruHome">
      <div className="hero-section">
        <div className="hero-content">
          <h1>Welcome to BreakThru Fitness</h1>
          <p>Empowering women to begin their fitness journey with confidence and strength.</p>
        </div>
      </div>

      {/* Expandable Cards Section */}
      <div className="info-container">
        {/* Personalized Training Programs Card */}
        <div 
          className={`info-card personalized-training ${expandedCard === 'personalized-training' ? 'expanded' : ''}`}
          onClick={() => toggleCard('personalized-training')}
        >
          <div className="card-header">
            <i className="fas fa-dumbbell icon"></i>
            <div>
              <h3>Personalized Training Programs</h3>
              <p className="cardtext">Customized workout plans tailored to your fitness goals.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'personalized-training' && <div className="card-expanded-content"><div>Our personalized training programs are designed to fit your unique fitness level and goals. From beginners to advanced athletes, we have a plan to help you succeed.</div><a href="/personalized-training" className="cta-button">Discover More</a></div>}
        </div>

        {/* Nutrition and Wellness Guidance Card */}
        <div 
          className={`info-card nutrition-wellness ${expandedCard === 'nutrition-wellness' ? 'expanded' : ''}`}
          onClick={() => toggleCard('nutrition-wellness')}
        >
          <div className="card-header">
            <i className="fas fa-apple-alt icon"></i>
            <div>
              <h3>Nutrition and Wellness Guidance</h3>
              <p className="cardtext">Expert advice on eating well and maintaining a healthy lifestyle.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'nutrition-wellness' && <div className="card-expanded-content"><div>Receive personalized nutrition and wellness advice to complement your fitness routine. Our experts are here to guide you towards a balanced and healthy lifestyle.</div><a href="/nutrition-wellness" className="cta-button">Discover More</a></div>}
        </div>

        {/* Community Support and Motivation Card */}
        <div 
          className={`info-card community-support ${expandedCard === 'community-support' ? 'expanded' : ''}`}
          onClick={() => toggleCard('community-support')}
        >
          <div className="card-header">
            <i className="fas fa-users icon"></i>
            <div>
              <h3>Community Support and Motivation</h3>
              <p className="cardtext">Join a supportive community of like-minded women.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'community-support' && <div className="card-expanded-content"><div>Connect with other women on their fitness journeys. Share experiences, stay motivated, and celebrate your achievements together.</div><a href="/community-support" className="cta-button">Discover More</a></div>}
        </div>

        {/* Success Stories and Testimonials Card */}
        <div 
          className={`info-card success-stories ${expandedCard === 'success-stories' ? 'expanded' : ''}`}
          onClick={() => toggleCard('success-stories')}
        >
          <div className="card-header">
            <i className="fas fa-star icon"></i>
            <div>
              <h3>Success Stories and Testimonials</h3>
              <p className="cardtext">Read inspiring stories from women who transformed their lives.</p>
              <p className="cardcta">Learn More</p>
            </div>
          </div>
          {expandedCard === 'success-stories' && <div className="card-expanded-content"><div>Discover the journeys of women who have achieved their fitness goals with BreakThru Fitness. Be inspired by their dedication and success.</div><a href="/success-stories" className="cta-button">Discover More</a></div>}
        </div>
      </div>

      {/* Additional Information Section */}
      <div className="about-us-section about-card">
        <div className="about-content">
          <h2>Join the BreakThru Fitness Community</h2>
          <p className="cardtext">BreakThru Fitness is dedicated to helping women start and sustain their fitness journeys. Our comprehensive programs and supportive community are here to empower you every step of the way.</p>
          <a href="/join-us" className="cta-button">Discover More</a>
        </div>
      </div> 
    </div>
  );
};

export default BreakThruFitness;
